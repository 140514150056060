// this is for common path for api

// this api is for development purpose
const commonApiPath = `https://mdqualityapps.in/API/dsc/`;

const login = `${commonApiPath}user_login` 

const get_form = `${commonApiPath}get_form`  

const add_form = `${commonApiPath}add_form`

const update_form = `${commonApiPath}update_form`  

export { login, get_form, add_form, update_form  }