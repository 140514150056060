const FilterData = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFieldThree, searchFieldEight, searchFieldNine, searchFieldEleven ,  searchFieldTen ,   searchFieldFour, searchFieldFive, searchFieldSix, searchFieldSeven ,     } = searchfeild;
  if (search === '') {
    return data
  } else if (
    (searchFeildOne && searchFeildOne.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFeildTwo && searchFeildTwo.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldThree && searchFieldThree.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldFour && searchFieldFour.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldFive && searchFieldFive.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldSix && searchFieldSix.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldSeven && searchFieldSeven.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldEight && searchFieldEight.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldNine && searchFieldNine.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldTen && searchFieldTen.toString().toLowerCase().includes(search.toLowerCase())) ||
    (searchFieldEleven && searchFieldEleven.toString().toLowerCase().includes(search.toLowerCase()))
  ) {
    return data;
  }
}

  const filterEmpOrders = (data, search, searchfeild) => {
    const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
    if (search === '') {
      return data
    } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toLowerCase().includes(search.toLowerCase())) {
      return data
    }
  }


export {FilterData,filterEmpOrders};