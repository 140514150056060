import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FilterData } from '../Components/FilterData'
import Filter from '../Components/Filter';
import axios from 'axios';
import { Container, Grid, Button, Card, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { ViewDataTable } from '../Services/Variable';
import { get_form } from '../Services/API_Services';
import { useNavigate } from 'react-router-dom';
import DeleteDataDialog from '../Components/DeleteDataDialogBox';

export default function ViewDataPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [search, setSearch] = useState('');
  const[data , setData] = useState([]);
  const [dataId, setDataId] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [openDeleteDataDialog, setOpenDeleteDataDialog] = useState(false);
  const UserId = localStorage.getItem('UserId');
  const UserRole = localStorage.getItem('UserRole');
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const getAllData = () => {
    const sendData = new FormData();
    if (UserRole === 'Admin'){
      sendData.append('UserRole', UserRole);
    }
    else
    {
      sendData.append('UserId', UserId);
    }
    axios({
      method: "POST",
      url: get_form,
      data: sendData
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        setData(res.data.data)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  useEffect(() => {
    getAllData();
  }, []);

  const handleOpenDelete = (id , compId , compName , LA ) =>{
    setOpenDeleteDataDialog(true);
    setDataId(id)
    setLoanAmount(LA)
    setCompanyId(compId)
    setCompanyName(compName)
  }

  return (
   <Box>
      <DeleteDataDialog CompanyId={companyId} LoanAmount={loanAmount} CompanyName={companyName} getAllData={getAllData} DataId={dataId} setDataId={setDataId} setOpenDeleteDataDialog={setOpenDeleteDataDialog} openDeleteDataDialog={openDeleteDataDialog} setMessage={setMessage} setOpen={setOpen} setStatus={setStatus} setColor={setColor} />
      <Box p={2}>
          <Box my={2} component={Card} sx={{ boxShadow: 5 }} p={2}>
            <Grid container>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='space-between' py={2} border='1px 1px 0px 1px solid silver'>
                  <Box>
                  <Button variant='contained' color='success' onClick={() => navigate('uploaddata')}
                      sx={{ height: '25px', borderRadius: 0, boxShadow: 0, fontWeight: 600 }}>UPLOAD DATA</Button>
                  </Box>
                  <Box px={2}>
                  <Button variant='contained' color='warning'
                    sx={{ height: '25px', borderRadius: 0, boxShadow: 0, fontWeight: 600 }}>EXPORT DATA</Button>
                  </Box>
                </Box>
              </Grid>
              <TableContainer >

                <Table sx={{ border: '1px solid silver' }} size='small' >
                  <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: '#eff8f2' }}>
                    <TableRow sx={{ borderBottom: '1px solid silver' }}>
                      {
                        ViewDataTable.map((i, index) => {
                          return (
                            <TableCell sx={{
                              textAlign: 'center', color: '#000000', fontWeight: 600, minHeight: '20px', borderRight: '1px solid silver',
                            }}>
                              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ my: 'auto' }}>
                                  {i.title}
                                </Box>
                                <Box>{
                                  i.filter &&
                                  <Filter search={search} setSearch={setSearch} />
                                }
                                </Box>
                              </Box>
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableHead>


                  <TableBody>

                    {
                      data.filter((data) => FilterData(data, search, {
                      searchFeildOne: data.Date,
                      searchFeildTwo: data.DSA,
                      searchFieldThree : data.Executive,
                      searchFieldFour :data.CustomerName,
                      searchFieldFive : data.LoanAmount,
                      searchFieldSix : data.CompanyName,
                      searchFieldSeven : data.CompanyId,
                      searchFieldEight: data.BankName,
                      searchFieldNine :data.Status,
                      searchFieldTen : data.Status1,
                      searchFieldEleven:data.Remarks,
                      })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((i, index) => {
                        return (
                          <TableRow key={index} sx={{
                            borderBottom: '1px solid silver',
                            '& .MuiTableCell-root': {
                              height: '20px',
                            }
                          }}>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.Date}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.DSA}
                            </TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.Executive}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.CustomerName}</TableCell>
                              <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.LoanAmount}</TableCell>
                              <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.CompanyName}</TableCell>
                              <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.CompanyId}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.BankName}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.Status}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.Status1}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>{i.Remarks}</TableCell>
                            <TableCell sx={{
                              textAlign: 'center', borderBottom: '1px solid silver', borderLeft: '1px solid silver',
                              borderRight: '1px solid silver',
                            }}>
                              <Box display='flex' flexDirection='row' justifyContent='center' gap={2}>
                                <Button onClick={() => navigate('editdata' , {state:{id:i.Id}})} variant="contained" sx={{ height: 25, width: 60, fontWeight: 600, opacity: 0.9, fontSize: 10 }} ><DriveFileRenameOutlineIcon fontSize='small' sx={{ verticalAlign: 'middle'}} /> EDIT</Button>
                                <Button onClick={()=>handleOpenDelete(i.Id , i.CompanyId , i.CompanyName , i.LoanAmount)} variant="contained" sx={{ height: 25, width: 70, fontWeight: 600, opacity: 0.9, fontSize: 10 }} color='error'><DeleteForeverIcon fontSize='small' sx={{ verticalAlign: 'middle' }} /> DELETE</Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }

                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={data.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </Box>

      </Box>
   </Box>
  )
}
