import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Box } from '@mui/material';
import axios from 'axios';
import { add_form } from '../Services/API_Services';

export default function DeleteDataDialog({ CompanyId , LoanAmount , CompanyName, getAllData, DataId, setDataId, setMessage, setOpen, setStatus, setColor, openDeleteDataDialog, setOpenDeleteDataDialog }) {

    const handleClose = () => {
        setOpenDeleteDataDialog(false);
        setDataId('');
        getAllData();
    }

    const handleDelete = () => {
        if (DataId !== '' && DataId !== null) {
            const sendData = new FormData();
            sendData.append('Id', DataId);
            axios({
                method: "POST",
                url: add_form,
                data: sendData
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    handleClose();
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }

    return (
        <div>
            <Dialog
                open={openDeleteDataDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"ARE YOU SURE THAT YOU WANNA DELETE THIS DATA PERMANENTLY?"}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant='subtitle1' fontWeight={600} color='#616e80'>Company ID: {CompanyId}</Typography>
                        <Typography variant='subtitle1' fontWeight={600} color='#616e80'>Company Name: {CompanyName}</Typography>
                        <Typography variant='subtitle1' fontWeight={600} color='#616e80'>Loan Amount: {LoanAmount}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='primary'>CLOSE</Button>
                    <Button onClick={handleDelete} variant='outlined' autoFocus color='error'>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
