import { AppBar, Box, Grid, IconButton, InputAdornment, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react'
import ResponsiveBd from '../Components/ResponsiveBd';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useNavigate } from 'react-router-dom';
import Logo from '../Assets/Sublime.jpeg';

function NavBar({ mobileOpen, setMobileOpen }) {

  const navigate = useNavigate();

  const logout = () => {
    navigate('logout');
    localStorage.clear()
  }

  return (
    //#e6ffe6
    <Box sx={{ width: '100%' }}>
      <AppBar position="sticky" sx={{ py: 1, width: '100%', bgcolor: '#F9F9FB', color: 'black', boxShadow: 1 }}>
        <Toolbar>
          <Grid container rowSpacing={{ xs: 4 }} justifyContent='space-between'>
            <Grid item sm={5} xs={12} md={3} lg={3} xl={3} display='flex' justifyContent={{ xl: 'start', lg: 'start', md: 'start', sm: 'start', xs: 'center' }}>
              <img src={Logo} style={{ objectFit: 'contain', height: '15vh' , mixBlendMode:'multiply' }} alt="DSC Logo" />
            </Grid>


            <Grid item sm={3} xs={6} md={3} lg={3} xl={3} justifyContent='end' display='flex' flexDirection='row' alignItems='center'>
              <PowerSettingsNewIcon sx={{ color: '#616e80', verticalAlign: 'middle', mr: 2, cursor: 'pointer' }} onClick={logout} />
            </Grid>

          </Grid>

        </Toolbar>

      </AppBar>
    </Box>
  )
}

export default NavBar