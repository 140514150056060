import React from 'react'
import { Box, Button, Grid, TextField, Stack, Autocomplete } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import SnackBar from '../Components/SnackBar';
import { appendData } from '../Services/Variable';
import { add_form } from '../Services/API_Services';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../Components/Breadcrumbs';


export default function UploadDataPage() {

  const [date, setDate] = useState('');
  const [dsa, setDsa] = useState('');
  const [executive, setExecutive] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [bankName, setBankName] = useState('');
  const [status, setStatus] = useState('');
  const [status1, setStatus1] = useState('');
  const [remarks, setRemarks] = useState('');
  const UserId = localStorage.getItem('UserId');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [statuses, setStatuses] = useState();
  const [color, setColor] = useState();
  const navigate = useNavigate();

  const handleSubmit = () => {
    const obj = {
      UserId: UserId,
      Date: date,
      DSA: dsa,
      Executive: executive,
      CustomerName: customerName,
      LoanAmount: loanAmount,
      CompanyName: companyName,
      CompanyId: companyId,
      BankName: bankName,
      Status: status,
      Status1:status1,
      Remarks:remarks,
    }

    const sendData = appendData(obj);
    axios({
      method: 'POST',
      url: add_form,
      data: sendData,
    })
      .then((res) => {
        if (res.data.error) {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(false);
          setColor(false);
        } else {
          setMessage(res.data.message);
          setOpen(true);
          setStatus(true);
          setColor(true);
          navigate('/home');
          setDate('');
          setDsa('');
          setExecutive('');
          setCustomerName('');
          setLoanAmount('');
          setCompanyName('');
          setCompanyId('');
          setBankName('');
          setStatus('');
          setStatus1('');
          setRemarks('');
        }
      })
      .catch((err) => {
        alert("Oops something went wrong " + err);
      });
  };

  const Cancel = () => {
    navigate('/home');
  }


  return (
   <Box>

    <Box py={2} px={1}>
    <Breadcrumb
    previous={'Home'}
    current={'Upload Data'}
    link1={`/home`}  
    />
    </Box>

      <SnackBar open={open} message={message} setOpen={setOpen} status={statuses} color={color} />
    <Box p={3}>
      <Box sx={{ border: '1px solid #d2cbcb' , borderRadius: '10px', boxShadow: 4 }} p={2}>
        <Grid container display="flex" justifyContent='center' sx={{ textAlign: 'center' }} spacing={3} >
          <Grid item lg={12} xl={12} >

          
              <Box sx={{ pb: 4, textAlign: 'left' }}>
                <h5>UPLOAD INFORMATION</h5>
              </Box>

              <Grid container justifyContent='start' spacing={2}>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Date" 
                    value={date} 
                    onChange={(e) => setDate(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='date'
                    InputLabelProps={{shrink:true}}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="DSA" 
                    value={dsa} 
                    onChange={(e) => setDsa(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Executive" 
                    value={executive} 
                    onChange={(e) => setExecutive(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Customer Name" 
                    value={customerName} 
                    onChange={(e) => setCustomerName(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Loan Amount" 
                    value={loanAmount} 
                    onChange={(e) => setLoanAmount(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Company Name" 
                    value={companyName} 
                    onChange={(e) => setCompanyName(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Company ID" 
                    value={companyId} 
                    onChange={(e) => setCompanyId(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Bank Name" 
                    value={bankName} 
                    onChange={(e) => setBankName(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Status" 
                    value={status} 
                    onChange={(e) => setStatus(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Status 1" 
                    value={status1} 
                    onChange={(e) => setStatus1(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} sx={{ py: 1 }}  >
                  <TextField
                    fullWidth
                    id="Name"
                    label="Remarks" 
                    value={remarks} 
                    onChange={(e) => setRemarks(e.target.value)}
                    variant="outlined"
                    size="small"
                    color="primary"
                    type='text'
                  />
                </Grid>

              </Grid>
          


          </Grid >
        </Grid>

        {/* {buttons}  */}

        <Grid container justifyContent='center' sx={{ textAlign: 'center' }}>
        <Grid item lg={6} xl={6} xs={12} >
        <Grid container justifyContent='space-evenly' alignItems='center'>
        <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }} >
        <Stack spacing={2} direction="row" >
        <Button fullWidth onClick={handleSubmit} variant="outlined" color='primary'
        >Submit</Button>
        </Stack>
        </Grid>

        <Grid item lg={3} sm={3} xl={3} xs={3} md={3} sx={{ py: 2 }}>
        <Stack spacing={2} direction="row">
                    <Button fullWidth variant="outlined" onClick={Cancel} color='error'
        >Cancel</Button>
        </Stack>
        </Grid>
        </Grid>


          </Grid>
        </Grid>
        </Box>
      </Box>
   </Box>
  )
}
