import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import axios from "axios";
import { Navigate, useNavigate, useRoutes } from 'react-router-dom'
import { login } from "../Services/API_Services";
import { useState } from "react";

export default function LoginPage({
  setsuccessOpen,
  setsuccessMessage,
  setsuccessStatus,
  setsuccessColor,
}) {


  const navigate = useNavigate();
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const serverData = new FormData()
    serverData.append('Mobile', mobile);
    serverData.append('UserPassword', password);
    if (!navigator.onLine) {
      setsuccessOpen(true)
      setsuccessMessage('Your internet is in Offline')
      setsuccessStatus(true)
      setsuccessColor(true)
    } else {
      axios({
        method: 'POST',
        url: login,
        data: serverData,
      }).then(res => {
        if (res.data.error) {
          setsuccessOpen(true)
          setsuccessMessage(res.data.message)
          setsuccessStatus(true)
          setsuccessColor(true)
        } else {
          localStorage.setItem('DSCAuth', true);
          localStorage.setItem('UserId', res.data.data.UserId);
          localStorage.setItem('UserName', res.data.data.UserName);
          localStorage.setItem('UserRole', res.data.data.UserRole);
          navigate(`/home`)
          setsuccessOpen(true)
          setsuccessMessage(res.data.message)
          setsuccessStatus(true)
          setsuccessColor(true)

        }
      }).catch(err => {
        alert('Oops something went wrong ' + err);
      });
    }

  }


  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: "url(https://source.unsplash.com/random)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={3}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Sign In
              </Typography>
              <Box
                component="form"
                onSubmit={onSubmit}
                sx={{ mt: 2 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={mobile}
                  onChange={(e)=>setMobile(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}