import { lazy, useEffect, useState } from 'react';

// project imports
import { Navigate, useNavigate, useRoutes } from 'react-router-dom'
import React, { Suspense } from 'react'
import Layout from './Layout';
import Pagenotfound from './Pages/Pagenotfound';

// Component Section
import ViewDataPage from './Pages/ViewDataPage';
import UploadDataPage from './Pages/UploadDataPage';
import LoginPage from './Pages/LoginPage';
import EditDataPage from './Pages/EditDataPage';

// ==============================|| MAIN ROUTING ||============================== //

//this variable is for local development 
export const startUrl = `/`;


// ==============================|| ROUTING RENDER ||============================== //


function Routes() {
    const auth = localStorage.getItem('DSCAuth');
    const [successOpen, setsuccessOpen] = useState(false);
    const [successMessage, setsuccessMessage] = useState('');
    const [successStatus, setsuccessStatus] = useState(false);
    const [successColor, setsuccessColor] = useState(false);
    const navigate = useNavigate();



    const maxInactiveTime = 40 * 60 * 1000; // 60 minutes in milliseconds

    const [lastActivity, setLastActivity] = useState(Date.now());

    // Handle user activity
    useEffect(() => {
        const handleUserActivity = () => {
            setLastActivity(Date.now());
        };

        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('keypress', handleUserActivity);

        return () => {
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('keypress', handleUserActivity);
        };
    }, []);

    // Check for inactivity and redirect if necessary
    useEffect(() => {
        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastActivity > maxInactiveTime) {
                // Redirect to login page
                window.location.href = startUrl;
            }
        };

        const interval = setInterval(checkInactivity, 10000); // Check every 10 seconds

        return () => clearInterval(interval);
    }, [lastActivity]);



    return useRoutes([
        {
            path: `*`,
            element: <Pagenotfound />
        },
        {
            path: startUrl,
            element: <LoginPage setsuccessOpen={setsuccessOpen} setsuccessMessage={setsuccessMessage} setsuccessStatus={setsuccessStatus} setsuccessColor={setsuccessColor} />
        },
        {
            path: `${startUrl}home`,
            element: auth ? <Layout successOpen={successOpen} setsuccessOpen={setsuccessOpen} successMessage={successMessage} successStatus={successStatus} successColor={successColor} /> : <Navigate to={startUrl} />,

            children: [
                { path: `${startUrl}home`, element: <ViewDataPage /> },
                { path: `logout`, element: <Navigate to={startUrl} /> },
                { path: `uploaddata`, element: <UploadDataPage /> },
                { path: `editdata`, element: <EditDataPage /> },
            ]
        },
    ])

}

export default Routes

