const labels = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];


const ViewDataTable = [
    {
        title: 'Date',
        filter: Boolean(true)
    },
    {
        title: 'DSA',
        filter: Boolean(true)
    },
    {
        title: 'Executive',
        filter: Boolean(true)
    },
    {
        title: 'Customer Name ',
        filter: Boolean(true)
    },
    {
        title: 'Loan Amount',
        filter: Boolean(true)
    },
    {
        title: 'Company Name',
        filter: Boolean(true)
    },
        {
        title: 'Company ID',
            filter: Boolean(true)
    },
        {
        title: 'Bank Name',
            filter: Boolean(true)
    },
    {
        title: 'Status',
        filter: Boolean(true)
    },
    {
        title: 'Status 1',
        filter: Boolean(true)
    },
    {
        title: 'Remarks',
        filter: Boolean(true)
    },
    {
        title: 'Edit/Delete',
    },
]


const appendData = (data) => {
    const serverData = new FormData()
    for (var key in data) {
        serverData.append(key, data[key]);
    }
    return serverData
}


export { labels, ViewDataTable, appendData }